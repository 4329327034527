// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-datavis-index-js": () => import("./../../../src/pages/datavis/index.js" /* webpackChunkName: "component---src-pages-datavis-index-js" */),
  "component---src-pages-finances-2017-js": () => import("./../../../src/pages/finances/2017.js" /* webpackChunkName: "component---src-pages-finances-2017-js" */),
  "component---src-pages-finances-2018-js": () => import("./../../../src/pages/finances/2018.js" /* webpackChunkName: "component---src-pages-finances-2018-js" */),
  "component---src-pages-finances-2019-js": () => import("./../../../src/pages/finances/2019.js" /* webpackChunkName: "component---src-pages-finances-2019-js" */),
  "component---src-pages-finances-2020-js": () => import("./../../../src/pages/finances/2020.js" /* webpackChunkName: "component---src-pages-finances-2020-js" */),
  "component---src-pages-finances-2021-js": () => import("./../../../src/pages/finances/2021.js" /* webpackChunkName: "component---src-pages-finances-2021-js" */),
  "component---src-pages-finances-index-js": () => import("./../../../src/pages/finances/index.js" /* webpackChunkName: "component---src-pages-finances-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-av-selfcheckout-index-js": () => import("./../../../src/pages/projects/av-selfcheckout/index.js" /* webpackChunkName: "component---src-pages-projects-av-selfcheckout-index-js" */),
  "component---src-pages-projects-gas-stations-index-js": () => import("./../../../src/pages/projects/gas-stations/index.js" /* webpackChunkName: "component---src-pages-projects-gas-stations-index-js" */),
  "component---src-pages-projects-gulfstream-b-2-b-index-js": () => import("./../../../src/pages/projects/gulfstream-b2b/index.js" /* webpackChunkName: "component---src-pages-projects-gulfstream-b-2-b-index-js" */),
  "component---src-pages-projects-gulfstream-index-js": () => import("./../../../src/pages/projects/gulfstream/index.js" /* webpackChunkName: "component---src-pages-projects-gulfstream-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-leader-index-js": () => import("./../../../src/pages/projects/leader/index.js" /* webpackChunkName: "component---src-pages-projects-leader-index-js" */),
  "component---src-pages-projects-marytrufel-index-js": () => import("./../../../src/pages/projects/marytrufel/index.js" /* webpackChunkName: "component---src-pages-projects-marytrufel-index-js" */),
  "component---src-pages-projects-megafon-lk-index-js": () => import("./../../../src/pages/projects/megafon-lk/index.js" /* webpackChunkName: "component---src-pages-projects-megafon-lk-index-js" */),
  "component---src-pages-projects-yandex-14-index-js": () => import("./../../../src/pages/projects/yandex-14/index.js" /* webpackChunkName: "component---src-pages-projects-yandex-14-index-js" */),
  "component---src-pages-projects-yandex-sports-index-js": () => import("./../../../src/pages/projects/yandex-sports/index.js" /* webpackChunkName: "component---src-pages-projects-yandex-sports-index-js" */),
  "component---src-pages-projects-yandex-tablet-index-js": () => import("./../../../src/pages/projects/yandex-tablet/index.js" /* webpackChunkName: "component---src-pages-projects-yandex-tablet-index-js" */),
  "component---src-pages-projects-yandex-touch-index-js": () => import("./../../../src/pages/projects/yandex-touch/index.js" /* webpackChunkName: "component---src-pages-projects-yandex-touch-index-js" */),
  "component---src-pages-projects-yandex-tv-index-js": () => import("./../../../src/pages/projects/yandex-tv/index.js" /* webpackChunkName: "component---src-pages-projects-yandex-tv-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

